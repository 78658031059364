.subsection--content.mainMenu {
  grid-template-rows: auto;
}

.carsearcher-mobile {
  z-index: 3000;
  visibility: hidden;
  background-color: #2f2f2f79;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 57px;
  left: 0;
}

.carsearcher-content {
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  height: 85%;
  padding-top: 2em;
  display: grid;
  overflow-y: scroll;
}

.banner-find {
  -webkit-appearance: none;
  text-align: left;
  text-indent: 35px;
  cursor: pointer;
  background: #fff url("icon-search.3fdf0df2.svg") 10px / 15px no-repeat;
  border: .1px solid #a4a8a8;
  border-radius: 25px;
  width: 290px;
  height: 40px;
  overflow: hidden;
}

.banner-subsearcher {
  color: #1937b9;
}

.searcherGrid {
  display: block;
}

.contHeigh {
  max-height: 512px;
  padding: 20px 0;
}

.banner {
  background-color: #fff;
  grid-template-rows: 1fr 1fr;
  max-width: 100%;
  height: 5em;
  font-size: 16px;
  display: grid;
  overflow-y: scroll;
}

.banner--payment {
  border-top: .5px solid;
  margin: 30px 50px 0;
  padding-top: 20px;
}

.banner-search.banner.banner-back {
  text-align: center;
  text-indent: 0;
  color: #1937b9;
  cursor: pointer;
  border-color: #1937b9;
  grid-area: 1 / 1 / 1 / 2;
  height: 1.5em;
  margin: 5px;
  font-family: open-sans-light;
}

.banner-search.banner.banner-back.hide {
  cursor: pointer;
  opacity: .7;
  background-color: #22222283;
  border-color: #22222283;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-bottom: 0;
  position: absolute;
  top: 77%;
  right: 0;
  box-shadow: 0 0 #000;
}

.banner-search {
  -webkit-appearance: none;
  cursor: pointer;
  border: .1px solid #a4a8a8;
  border-radius: 25px;
  margin: 5px;
  padding: 10px;
}

.caricon {
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  height: 8.5em;
  display: grid;
}

.caricon--img {
  grid-area: 1 / 1 / 1 / 3;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
}

.caricon--name {
  text-align: center;
  text-indent: 0;
  grid-area: 2 / 1 / 2 / 3;
  font-family: open-sans-semibold;
  font-size: 16px;
}

.caricon--price {
  text-indent: 0;
  text-align: right;
  color: #1937b9;
  grid-area: 2 / 2 / 2 / 2;
  align-items: end;
  padding-right: 1em;
  font-family: open-sans-light;
  font-style: italic;
  display: grid;
}

.searcherbrand--img {
  height: 50px;
  margin-left: auto;
  margin-right: auto;
}

.searcherbrand--text {
  text-align: center;
  align-items: end;
  display: grid;
}

.showallcars {
  text-align: center;
  text-indent: 0;
  cursor: pointer;
  border-color: #1937b9;
  grid-area: 1 / 2 / 1 / 3;
  height: 1.5em;
  margin: 5px;
  font-family: open-sans-light;
}

/*# sourceMappingURL=index.681de2ad.css.map */
