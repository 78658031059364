

.subsection--content.mainMenu {
    grid-template-rows: auto;
  }

 
.carsearcher-mobile{
  position:absolute;
  top:57;
  left:0;
  background-color: #2f2f2f79;
  width:100%; 
  height:100%; 
  z-index: 3000;
  visibility: hidden;
}

.carsearcher-content{
  padding-top: 2em;
  height:85%;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content}

  .banner-find {
    -webkit-appearance: none;
    width: 290px;
    text-align: left;
    text-indent: 35px;
    border-radius: 25px;
    border-style: solid;
    border-color: #a4a8a8;
    border-width: 0.1px;
    background: url(../../../dist/data/icon-search.svg) no-repeat 10px;
    background-size: 15px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    overflow: hidden;
  }
  

  .banner-subsearcher {
    color: #1937b9;
  }
  
  .searcherGrid {
    display: block;
  }

  .contHeigh {
    max-height: 512px;
    padding: 20px 0;
    }
  
  .banner {
    max-width: 100%;
    overflow-y: scroll;
    font-size: 16px;
    height: 5em;
    display: grid;
    grid-template-rows: 1fr 1fr;
    background-color: white;


  }
  
  .banner--payment{
    margin-top:30px;
    margin-bottom:0;
    margin-left:50px;
    margin-right: 50px;
    padding-top:20px;
    border-top: 0.5px solid;
  }


  .banner-search.banner.banner-back {
  text-align: center;
  text-indent: 0;
  color: #1937b9;
  cursor: pointer;
  border-color: #1937b9;
  margin: 5px;
  grid-area: 1/1/1/2;
  height: 1.5em;
  font-family: open-sans-light;
}

.banner-search.banner.banner-back.hide{
  position: absolute;
  top:77%;
  right:0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: .7;
  background-color: #22222283;
  border-radius: 50%;
  margin-bottom: 0;
  box-shadow: 0 0 #000;
  border-color: #22222283;


}

.banner-search {
margin-left: auto;
margin-right: auto;
-webkit-appearance: none;
/*text-indent: 70px;*/
cursor: pointer;
border: .1px solid #a4a8a8;
border-radius: 25px;
margin: 5px;
padding: 10px;
}
  


 .caricon{
  display:grid;
  height:8.5em;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;

 } 

 .caricon--img{
  width:150px;
  grid-area:1/1/1/3;
  margin-left:auto;
  margin-right:auto;
 }

 .caricon--name{
  grid-area: 2/1/2/3;
  text-align: center;

  font-family: open-sans-semibold;
  font-size: 16px;
  text-indent: 0;
 }

 .caricon--price{
  grid-area: 2/2/2/2;
  text-indent: 0;
display: grid;
align-items: end;
text-align: right;
padding-right: 1em;
color: #1937b9;
  font-family: open-sans-light;
  font-style: italic;
 }

 .searcherbrand--img{
  height: 50px;
  margin-left: auto;
  margin-right: auto
 }

 .searcherbrand--text{
  text-align: center;
  display: grid;

  align-items: end;
 }

.showallcars{
  text-align: center;
  text-indent: 0;
  cursor: pointer;
  border-color: #1937b9;
  margin: 5px;
  grid-area: 1/2/1/3;
  height: 1.5em;
  font-family: open-sans-light;
}